import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import './featureTitle.scss';

const FeatureTitle = props => {
  return (
    <div className="pricing__feature-title">
      <div className="pricing__feature-title-icon-container">
        <Icon
          className="pricing__feature-title-icon"
          iconName={props.iconName}
          iconComponent={props.iconComponent}
        />
      </div>
      <h3 className="pricing__feature-title-text">{props.title}</h3>
    </div>
  );
};

FeatureTitle.propTypes = {
  iconName: PropTypes.string,
  iconComponent: PropTypes.object,
  title: PropTypes.string.isRequired
};

export default FeatureTitle;
