const generateToken = action => {
  return new Promise((resolve, reject) => {
    const reCaptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
    const captcha = window.grecaptcha;
    if (!window || !captcha) {
      reject('Recaptcha has not been loaded');
    }

    captcha.enterprise.ready(() => {
      captcha.enterprise
        .execute(reCaptchaSiteKey, { action: action })
        .then(token => {
          resolve(token);
        })
        .catch(error => reject(error));
    });
  });
};

const postData = async data => {
  const response = await fetch(
    `${process.env.REACT_APP_SMILECLOUD_VALIDATION_API}/v1/waitlist/join`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  );

  if (!response.ok) {
    return false;
  }

  return true;
};

export const joinWaitList = async (email, language) => {
  try {
    const action = 'JOIN_WAIT_LIST';
    const token = await generateToken(action);

    if (!token) return false;

    const joinWaitListPayload = {
      token,
      action,
      userLang: language,
      email
    };

    const response = await postData(joinWaitListPayload);

    return response;
  } catch (err) {
    return false;
  }
};
