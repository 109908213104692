import React from 'react';
import { useTranslation } from 'react-i18next';
import { REGISTER_URL } from '../../common/constants';

import './theme.scss';
import Footer from '../../footer';

function MobileFooter() {
  const { t } = useTranslation();
  return (
    <section id="mf">
      <div className="mf-row">
        <span className="mf-small-text">{t('redefine.title')}</span>
        <div className="mf-header-mobile">
          <div className="mf-big-hero-message">{t('dentist.hero.title')}</div>
        </div>
      </div>
      <div className="mf-row">
        <a href={REGISTER_URL} className="as-link dark hero">
          <div
            style={{ textAlign: 'center' }}
            className="button primary main mobile-hero"
          >
            {t('redefine.start')}
          </div>
        </a>
      </div>
      <div className="mf-spacer"></div>
      <Footer theme="for-mobile" type="minimal" source="mobile" />
    </section>
  );
}

export default MobileFooter;
