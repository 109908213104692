import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MetalabLogo } from '../../../common/pricing/metalab.svg';
import OutsourceFeatures from './OutsourceFeatures';
import './outsource.scss';

const Outsource = () => {
  const { t } = useTranslation();

  return (
    <>
      <article className="pricing__outsource">
        <section className="pricing__outsource-aside">
          <h2 className="pricing__outsource-aside-title">
            {t('v2.pricing.outsource.title')}
          </h2>
          <MetalabLogo />
          <p className="pricing__outsource-aside-description-light">
            {t('v2.pricing.outsource.createdByDentcof')}
          </p>
          <p className="pricing__outsource-aside-description">
            {t('v2.pricing.outsource.placeOrders')}
          </p>
        </section>
        <OutsourceFeatures />
      </article>
    </>
  );
};

export default Outsource;
