import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import aiDesktop from '../../common/ai/desktop.mp4';
import aiDesktopPoster from '../../common/ai/desktop_poster.png';

import aiMobile from '../../common/ai/mobile.mp4';
import aiMobilePoster from '../../common/ai/mobile_poster.png';

import library from '../../common/illustrations/library@2x.png';
import library1X from '../../common/illustrations/library.png';

import './theme.scss';

class AI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldX: 0,
      oldY: 0
    };
  }

  componentDidMount() {
    if (this.video) {
      const { onLoad } = this.props;
      this.video.controls = false;
      this.video.width = '100vw';
      if (onLoad) onLoad(this.video, this.libVideo);
    }
  }

  onOverlayClick = () => {
    if (this.video) this.video.play();
  };

  render() {
    const { isMobile, t } = this.props;
    const videoSrc = isMobile ? aiMobile : aiDesktop;
    const videoPosterSrc = isMobile ? aiMobilePoster : aiDesktopPoster;
    return (
      <section id="ai">
        <div className="ai-message top">
          <div className="big-ai-message">{t('ai.title')}</div>
          <div className="small-ai-message">{t('ai.info')}</div>
        </div>
        <div className="ai-natural black">
          <video
            poster={videoPosterSrc}
            ref={ref => (this.video = ref)}
            loop
            muted
            preload={'auto'}
            playsInline
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
          <div className="ai-message bottom">
            <div className="big-ai-message">{t('libraries.download')}</div>
            <div className="small-ai-message">{t('libraries.design')}</div>
            <span className="patent">{t('ai.patent')}</span>
            <img
              srcSet={`${library1X} 648w, ${library} 1296w`}
              src={library1X}
              className="ai-download-teeth"
              alt={t('libraries.download')}
            />
          </div>
          <div
            className="overlay cover"
            ref={ref => (this.overlay = ref)}
            onClick={this.onOverlayClick}
          />
        </div>
      </section>
    );
  }
}

export default withTranslation()(AI);
