import React, { Component } from 'react';

import ScrollToTopOnMount from '../scrollToTopOnMount';

import Hero from './hero';
import AI from './ai';
import Header from '../header';

import Cloud from './easyAccess/Cloud';
import SmileDesign from './easyAccess/SmileDesign';
import ConnectMobile from './easyAccess/ConnectMobile';
import MobileFooter from './mobileFooter';
import AppStayConnected from './appStayConnected';
import ReviewMobile from './easyAccess/ReviewMobile';
import Experience from './metalab/Experience';
import Guiding from './metalab/Guiding';

class HomeMobile extends Component {
  constructor(props) {
    super(props);

    this.options = {
      licenseKey: process.env.REACT_APP_FULLPAGE_LICENSE,
      anchors: [
        'intuitive',
        'cloud',
        'connect',
        'design',
        'review',
        'natural',
        'experience',
        'scale',
        'connected',
        'smiles',
        'footer'
      ],
      onLeave: this.onSectionLeave.bind(this),
      navigation: false,
      touchSensitivity: 15,
      autoScrolling: false,
      fitToSection: false
    };
  }

  componentDidMount() {
    window.fp = new window.fullpage('#fullpage', this.options);
  }

  componentWillUnmount() {
    window.fp.destroy();
    var fpNav = document.getElementById('fp-nav');
    if (fpNav) fpNav.remove();
  }

  onSectionLeave = (origin, destination, direction) => {
    if (origin.anchor === 'intuitive' && direction === 'down') {
      this.setState({ headerSize: 'small' });
    }
    if (origin.anchor === 'cloud' && direction === 'up') {
      if (this.heroVideo) this.heroVideo.play();

      this.setState({ headerSize: null });
    }
    if (
      (origin.anchor === 'review' && direction === 'down') ||
      (origin.anchor === 'experience' && direction === 'up')
    ) {
      if (this.aiVideo) this.aiVideo.play();
      if (this.aiLibVideo) this.aiLibVideo.play();
    }
    if (origin.anchor === 'natural') {
      if (this.aiVideo) {
        this.aiVideo.pause();
        this.aiVideo.currentTime = 0;
      }

      if (this.aiLibVideo) {
        this.aiLibVideo.pause();
        this.aiLibVideo.currentTime = 0;
      }
    }
    if (
      (origin.anchor === 'intuitive' && direction === 'down') ||
      (origin.anchor === 'connect' && direction === 'up')
    ) {
      if (this.smileVideo) this.smileVideo.play();
    }
    if (
      (origin.anchor === 'cloud' && direction === 'down') ||
      (origin.anchor === 'design' && direction === 'up')
    ) {
      if (this.reviewVideo) this.reviewVideo.play();
    }
  };

  onAiLoad = (video, libVideo) => {
    this.aiVideo = video;
    this.aiLibVideo = libVideo;
  };

  onHeroLoad = video => {
    this.heroVideo = video;
  };

  onSmileLoad = video => {
    this.smileVideo = video;
  };

  onReviewLoad = video => {
    this.reviewVideo = video;
  };

  render() {
    return (
      <section id="home">
        <ScrollToTopOnMount />
        <Header isMobile={true} theme="light" fixed={true} />

        <div id="fullpage">
          <div className="section" id="section0">
            <Hero isMobile={true} onLoad={this.onHeroLoad} />
          </div>
          <div className="section fp-auto-height" id="section3">
            <SmileDesign onLoad={this.onSmileLoad} />
          </div>
          <div className="section fp-auto-height" id="section4">
            <ReviewMobile onLoad={this.onReviewLoad} />
          </div>
          <div className="section fp-auto-height" id="section1">
            <Cloud />
          </div>
          <div className="section fp-auto-height" id="section2">
            <ConnectMobile />
          </div>
          <div className="section fp-auto-height" id="section5">
            <AI onLoad={this.onAiLoad} isMobile={true} />
          </div>
          <div className="section fp-auto-height" id="section6">
            <Experience />
          </div>
          <div className="section fp-auto-height" id="section7">
            <Guiding />
          </div>
          <div className="section fp-auto-height" id="section8">
            <AppStayConnected />
          </div>
          <div className="section fp-auto-height" id="section9">
            <MobileFooter />
          </div>
        </div>
      </section>
    );
  }
}

export default HomeMobile;
