import React from 'react';
import Collapsible from './collapsible/Collapsible';
import './faqs.scss';
import { useTranslation } from 'react-i18next';

const Faqs = () => {
  const { t } = useTranslation();

  return (
    <section className="pricing__faqs">
      <h2 className="pricing__faqs-heading">{t('price.faq.privacy')}</h2>
      <Collapsible
        title={t('price.faq.q1')}
        content={t('v2.pricing.faq.canICancel.content')}
      />
      <Collapsible
        title={t('price.faq.q2')}
        content={t('v2.pricing.faq.doIHaveToWorry.content')}
      />
      <Collapsible
        title={t('price.faq.q3')}
        content={t('v2.pricing.faq.howCanIInvite.content')}
      />
      <Collapsible
        title={t('v2.pricing.faq.whoCanAccess.title')}
        content={t('v2.pricing.faq.whoCanAccess.content')}
      />
      <Collapsible
        title={t('v2.pricing.faq.doesEveryPerson.title')}
        content={t('v2.pricing.faq.doesEveryPerson.content')}
      />
      <Collapsible
        title={t('v2.pricing.faq.canIChange.title')}
        content={t('v2.pricing.faq.canIChange.content')}
      />

      <h2 className="pricing__faqs-heading">{t('price.faq.features')}</h2>
      <Collapsible
        title={t('v2.pricing.faq.whatIsABiometric.title')}
        content={t('v2.pricing.faq.whatIsABiometric.content')}
      />
      <Collapsible
        title={t('v2.pricing.faq.howDoesTheSearchAlgorithm.title')}
        content={t('v2.pricing.faq.howDoesTheSearchAlgorithm.content')}
      />
      <Collapsible
        title={t('v2.pricing.faq.canIPrint.title')}
        content={t('v2.pricing.faq.canIPrint.content')}
      />

      <h2 className="pricing__faqs-heading">{t('price.faq.cases')}</h2>
      <Collapsible
        title={t('v2.pricing.faq.canMyCollaboratorDelete.title')}
        content={t('v2.pricing.faq.canMyCollaboratorDelete.content')}
      />
      <Collapsible
        title={t('price.faq.q8')}
        content={t('v2.pricing.faq.areThereLimits.content')}
      />
      <Collapsible
        title={t('v2.pricing.faq.unlimitedCases.title')}
        content={t('v2.pricing.faq.unlimitedCases.content')}
      />
    </section>
  );
};

export default Faqs;
