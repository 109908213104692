import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import logo from '../resources/symbol_logo_passport@2x.png';
import passportMobile from '../resources/mobile_patient_passport@2x.png';
import passport from '../resources/patient_passport@2x.png';

import appStoreBadge from '../../common/apps/iOS/appstore_badge.svg';
import playStoreBadge from '../../common/apps/android/google-play-badge.svg';

import './patientContent.scss';

class PatientPassport extends Component {
  render() {
    const { t, isMobile } = this.props;
    return (
      <section className="patient-ea ea-full-width">
        <div className="easy-access">
          <div className="ea-row">
            <img
              className="ea-main-image"
              src={isMobile ? passportMobile : passport}
              alt="Passport"
            />

            <div className="ea-message-container">
              <div className="ea-message-title">
                <img
                  src={logo}
                  className="ea-message-title-logo"
                  alt="Smilecloud"
                />
                Smilecloud{' '}
                <span style={{ fontWeight: 300 }}>{t('common.passport')}</span>
              </div>

              <div className="ea-message-body">
                <p>{t('patient.passport')}</p>
              </div>
              <div className="asc-apps">
                <a
                  href="https://apps.apple.com/app/id1513182926"
                  target="_blank"
                  rel="noopener noreferrer"
                  alt="Smilecloud Passport App"
                >
                  <img
                    className="app-store-badge"
                    alt="Download on the"
                    src={appStoreBadge}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.smilecloud.android.smilecloudpassport&utm_source=www"
                >
                  <img
                    className="play-store-badge"
                    alt="Get it on Google Play"
                    src={playStoreBadge}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(PatientPassport);
