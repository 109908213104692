import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { TEXTS } from '../../common/constants';

import './patientFooter.scss';

import Logo from '../../logo';

export class PatientFooter extends Component {
  showHelp = () => {
    const zendesk = window.zE;
    if (zendesk) {
      zendesk.activate();
    }
  };

  render() {
    const { theme, type, t } = this.props;
    const isMinimal = type && type === 'minimal';
    return (
      <section id="footer">
        <div className={'footer ' + theme + ' ' + type}>
          <div className="row">
            <div className="navigation">
              {!isMinimal ? (
                <div className="footer-logo">
                  <NavLink to="/" className="as-link">
                    <Logo size="small" theme="light" />
                  </NavLink>
                </div>
              ) : null}
              <div className="footer-links">
                <ul>
                  <li>
                    <NavLink to="/pricing" className="as-link">
                      {t(TEXTS.pricing)}
                    </NavLink>
                  </li>
                  <li>
                    <a href="https://blog.smilecloud.com/" className="as-link">
                      {t(TEXTS.blog)}
                    </a>
                  </li>
                  <li>
                  <a
                      href="http://trust.smilecloud.com/legal/general-terms-of-service"
                      className="as-link"
                    >
                      {t(TEXTS.terms)}
                    </a>
                  </li>
                  <li>
                  <a
                      href="http://trust.smilecloud.com/legal/privacy-policy"
                      className="as-link"
                    >
                      {t(TEXTS.privacy)}
                    </a>
                  </li>
                  <li>
                    <a
                      href="http://trust.smilecloud.com/legal/cookie-policy"
                      className="as-link"
                    >
                      {t(TEXTS.cookiesPolicy)}
                    </a>
                  </li>
                  <li className="as-link">
                    <span onClick={this.showHelp} className="as-link">
                      {t(TEXTS.contactUs)}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

PatientFooter.propTypes = {
  theme: PropTypes.string,
  type: PropTypes.string
};

PatientFooter.defaultProps = {
  theme: 'dark',
  type: 'full'
};

export default withTranslation()(PatientFooter);
