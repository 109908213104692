import React, { useRef, useState, useEffect } from 'react';
import './LanguagesDropdown.scss';
import languageIcon from '../common/illustrations/language.svg';
import { LANGUAGES } from '../common/constants';

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

const LanguagesDropdown = ({ i18n, theme }) => {
  const ref = useRef();
  const [show, setShow] = useState(false);

  useOnClickOutside(ref, () => {
    setShow(false);
  });

  const toggleMenu = (value = undefined) => {
    if (value !== undefined) {
      setShow(value);
      return;
    }

    setShow(!show);
  };

  const changeLanguage = newLanguage => () => {
    i18n.changeLanguage(newLanguage);
    window.Cookiebot &&
      window.Cookiebot.scriptElement.setAttribute('data-culture', newLanguage);
    window.Cookiebot && window.Cookiebot.runScripts();
    toggleMenu(false);
  };

  return (
    <div ref={ref} className="langauges-container">
      <div onClick={() => toggleMenu()} className="languages-button">
        <img
          src={languageIcon}
          className={`img ${theme}`}
          alt="language-icon"
        />
        <div className={`arrow-down ${theme}`} />
      </div>

      <div className={`languages-dropdown ${show && `show`}`}>
        {LANGUAGES.map(language => (
          <div
            key={language.value}
            className={`item ${i18n.language === language.value && `active`}`}
            onClick={changeLanguage(language.value)}
          >
            {language.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguagesDropdown;
