import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './fullComparisonRow.scss';
import { ReactComponent as CheckIcon } from '../../../common/pricing/check.svg';

const TableData = props => {
  return (
    <td {...props} className="pricing__comparison-table-data">
      {props.value === true ? (
        <CheckIcon className="pricing__comparison-table-check" />
      ) : (
        props.value
      )}
    </td>
  );
};

const FullComparisonRow = props => {
  return (
    <>
      <tr className="pricing__comparison-table-row">
        <td
          className={classnames('pricing__comparison-table-data-title', {
            'pricing__comparison-table-data-title-section': props.isSectionTitle
          })}
        >
          {props.name}
        </td>
        <TableData value={props.starter} />
        <TableData value={props.business} />
        <TableData value={props.enterprise} />
        {!props.isSectionTitle && (
          <td className="pricing__comparison-table-data-hover" />
        )}
      </tr>
    </>
  );
};

const cellPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.bool]);

TableData.propTypes = {
  value: cellPropType
};

FullComparisonRow.propTypes = {
  name: PropTypes.string.isRequired,
  starter: cellPropType.isRequired,
  business: cellPropType.isRequired,
  enterprise: cellPropType.isRequired,
  isSectionTitle: PropTypes.bool
};

export default FullComparisonRow;
