import React from 'react';
import { useTranslation } from 'react-i18next';

import storeCases from '../../common/illustrations/1@2x.png';
import storeCases1X from '../../common/illustrations/1.png';
import documentation from '../../common/illustrations/documentation.svg';

import './theme.scss';

const Cloud = () => {
  const { t } = useTranslation();
  return (
    <section className="ea-full-width">
      <div className="easy-access">
        <div className="ea-row">
          <div className="ea-header">
            <img
              src={documentation}
              className="ea-title-icon"
              alt={t('easyAccess.main.menu.cloud')}
            />
            {t('easyAccess.main.menu.cloud')}
          </div>
          <img
            srcSet={`${storeCases1X} 648w, ${storeCases} 1296w`}
            src={storeCases1X}
            className="main-image"
            alt={t('easyAccess.main.menu.cloud')}
          />

          <div className="ea-message-container">
            <div className="ea-message-title multiline">
              {t('easyAccess.storeCases.info1')
                .split('\n')
                .map(text => (
                  <span key={text}>{text}</span>
                ))}
            </div>
            <div className="ea-message-body">
              <p>{t('easyAccess.storeCases.info2')}</p>

              <p>{t('easyAccess.storeCases.info3')}</p>

              <p>{t('easyAccess.storeCases.info4')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cloud;
