import React from 'react';
import { useTranslation } from 'react-i18next';

import appIOS1x from '../../common/apps/iOS/On_iphone.png';
import appIOS from '../../common/apps/iOS/On_iphone@2x.png';
import appStoreBadge from '../../common/apps/iOS/appstore_badge.svg';
import playStoreBadge from '../../common/apps/android/google-play-badge.svg';

import './theme.scss';

function AppStayConnected() {
  const { t } = useTranslation();
  return (
    <section className="asc-full-width">
      <div className="apps-stay-connected">
        <div className="apc-mobile-title">{t('connected.mobile')}</div>
        <div className="asc-row">
          <img
            className="asc-main-image "
            srcSet={`${appIOS1x} 648w, ${appIOS} 1296w`}
            src={appIOS}
            alt="Stay Connected"
          />
          <div className="asc-content">
            <div className="asc-apps">
              <a
                href="https://apps.apple.com/app/id1463986434"
                target="_blank"
                rel="noopener noreferrer"
                alt="Smilecloud App"
              >
                <img
                  className="app-store-badge"
                  alt="Download on the"
                  src={appStoreBadge}
                />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.smilecloud.android.app&utm_source=www"
              >
                <img
                  className="play-store-badge"
                  alt="Get it on Google Play"
                  src={playStoreBadge}
                />
              </a>
            </div>

            <div className="asc-message-container ">
              <div className="asc-title">{t('connected.title')}</div>

              <div className="asc-message-body">
                <p>{t('connected.info1')}</p>
                <p>{t('connected.info2')}</p>
                <p>{t('connected.info3')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AppStayConnected;
