import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from './Modal';
import FullComparisonHead from './full-comparison/FullComparisonHead';
import FullComparisonBody from './full-comparison/FullComparisonBody';
import './comparison.scss';
import PricingButton from '../shared/button/PricingButton';
import { DENTIST_REGISTER_URL } from '../../common/constants';

const Comparison = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="pricing__comparison-button-container">
        <button
          className="pricing__comparison-button as-link"
          onClick={() => setIsModalOpen(true)}
        >
          {t('v2.pricing.comparison.seeFull')}
        </button>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <section className="pricing__comparison-table-container">
          <h2 className="pricing__comparison-title">
            {t('v2.pricing.comparison.title')}
          </h2>
          <table className="pricing__comparison-table">
            <FullComparisonHead />
            <FullComparisonBody />
          </table>
          <div className="pricing__comparison-table-under">
            <div className="pricing__comparison-table-under-item" />
            <div className="pricing__comparison-table-under-item" />
            <div className="pricing__comparison-table-under-item" />
          </div>
          <div className="pricing__comparison-footer">
            <PricingButton
              className="pricing__comparison-footer-button"
              label={t('v2.pricing.subscription.trialDays')}
              variant="primary"
              onClick={() => (window.location.href = DENTIST_REGISTER_URL)}
            />
            <span className="pricing__comparison-footer-text">
              {t('v2.pricing.comparison.experience')}
            </span>
          </div>
        </section>
      </Modal>
    </>
  );
};

export default Comparison;
