import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

class Tier {
  constructor(name, starter, business, enterprise) {
    this.name = name;
    this.starter = starter;
    this.business = business;
    this.enterprise = enterprise;
    this.isSectionTitle = !starter && !business && !enterprise;
  }
}

export const useComparisonData = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      new Tier(t('common.accountManagement'), false, false, false),
      new Tier(
        t('v2.pricing.feature.teamWorkspace.onePlaceForAllTeamCases'),
        false,
        true,
        true
      ),
      new Tier(
        t('v2.pricing.feature.teamWorkspace.addMoreTeamMembers'),
        false,
        true,
        true
      ),
      new Tier(
        t('v2.pricing.comparison.teamMembersPermissions'),
        false,
        true,
        true
      ),

      new Tier(t('v2.pricing.comparison.storeData'), false, false, false),
      new Tier(
        t('v2.pricing.comparison.caseBasedArchitecture'),
        true,
        true,
        true
      ),
      new Tier(
        t('v2.pricing.feature.allStandardFeatures.cloudDocumentationLibrary'),
        true,
        true,
        true
      ),
      new Tier(t('v2.pricing.comparison.noCompression'), true, true, true),
      new Tier(t('v2.pricing.comparison.collections'), true, true, true),
      new Tier(
        t('v2.pricing.comparison.restoreCases'),
        `14 ${t('common.day', { count: 14 })}`,
        `365 ${t('common.day', { count: 365 })}`,
        `5 ${t('common.year', { count: 5 })}`
      ),
      new Tier(t('v2.pricing.comparison.photoEditor'), true, true, true),
      new Tier(t('v2.pricing.comparison.reorderFiles'), true, true, true),
      new Tier(t('v2.pricing.comparison.searchCasesByName'), true, true, true),
      new Tier(
        t('v2.pricing.comparison.searchDentalSpecialist'),
        true,
        true,
        true
      ),
      new Tier(t('v2.pricing.comparison.filterCases'), true, true, true),

      new Tier(t('v2.pricing.comparison.collaborate'), false, false, false),
      new Tier(t('v2.pricing.comparison.teamUp'), true, true, true),
      new Tier(
        t('v2.pricing.comparison.removeCaseTeamMember'),
        true,
        true,
        true
      ),
      new Tier(
        t('v2.pricing.comparison.virtualTreatmentRoom'),
        true,
        true,
        true
      ),
      new Tier(t('v2.pricing.comparison.messageMentions'), true, true, true),
      new Tier(t('v2.pricing.comparison.pushNotifications'), true, true, true),
      new Tier(t('v2.pricing.comparison.caseActivityLog'), true, true, true),
      new Tier(t('v2.pricing.comparison.articles'), true, true, true),
      new Tier(
        t('v2.pricing.comparison.filterInAppNotifications'),
        true,
        true,
        true
      ),
      new Tier(
        t('v2.pricing.comparison.annotationsOnPhotos'),
        true,
        true,
        true
      ),
      new Tier(
        t('v2.pricing.comparison.separateChatWithThePatient'),
        true,
        true,
        true
      ),

      new Tier('Smile design', false, false, false),
      new Tier(
        t('v2.pricing.comparison.photorealisticSmileDesigns'),
        true,
        true,
        true
      ),
      new Tier(
        t('v2.pricing.comparison.searchUniqueCompositions'),
        true,
        true,
        true
      ),
      new Tier(t('v2.pricing.comparison.colorMatching'), true, true, true),
      new Tier(t('v2.pricing.comparison.selectiveDesign'), true, true, true),
      new Tier(
        t('v2.pricing.comparison.compareBetweenDesigns'),
        true,
        true,
        true
      ),
      new Tier(t('v2.pricing.comparison.duplicateDesign'), true, true, true),
      new Tier(
        t('v2.pricing.comparison.download3DBiometric'),
        true,
        true,
        true
      ),
      new Tier(t('v2.pricing.comparison.order3DDesigns'), true, true, true),

      new Tier(t('v2.pricing.comparison._3dReview'), false, false, false),
      new Tier(t('v2.pricing.comparison._3dAlign'), true, true, true),
      new Tier(t('v2.pricing.comparison._3dAnnotations'), true, true, true),
      new Tier(t('v2.pricing.comparison.contactsHeatmap'), true, true, true),
      new Tier(
        t('v2.pricing.comparison.crossSectionWithMeasure'),
        true,
        true,
        true
      ),
      new Tier(t('v2.pricing.comparison.group3DObjects'), true, true, true),
      new Tier(t('v2.pricing.comparison.convertDicomTo3D'), false, true, true),
      new Tier(t('v2.pricing.comparison.cbctAnd3d'), false, true, true),
      new Tier(t('v2.pricing.comparison.jawMotion'), false, true, true),

      new Tier(
        t('v2.pricing.comparison.patientPassports'),
        false,
        false,
        false
      ),
      new Tier(
        t('v2.pricing.comparison.patientDataPassports'),
        true,
        true,
        true
      ),
      new Tier(t('v2.pricing.comparison.shareData'), true, true, true)
    ];
  }, [t]);
};
