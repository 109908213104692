import React from 'react';
import { useTranslation } from 'react-i18next';

import connect from '../../common/illustrations/mobile_connect@2x.png';
import connect1X from '../../common/illustrations/mobile_connect.png';
import connectIcon from '../../common/illustrations/connect.svg';

import './theme.scss';

const ConnectMobile = () => {
  const { t } = useTranslation();
  return (
    <section className="ea-full-width">
      <div className="easy-access ea-content">
        <div className="ea-row">
          <div className="ea-header">
            <img
              src={connectIcon}
              className="ea-title-icon"
              alt={t('easyAccess.main.menu.connect')}
            />
            {t('easyAccess.main.menu.connect')}
          </div>
          <img
            srcSet={`${connect1X} 648w, ${connect} 1296w`}
            src={connect1X}
            className="main-image top"
            alt={t('easyAccess.main.menu.connect')}
          />

          <div className="ea-message-container">
            <div className="ea-message-title multiline">
              {t('easyAccess.connect.title')
                .split('\n')
                .map(text => (
                  <span key={text}>{text}</span>
                ))}
            </div>

            <div className="ea-message-body">
              <p>{t('easyAccess.connect.description.line1')}</p>

              <p>{t('easyAccess.connect.description.line2')}</p>

              <p>{t('easyAccess.connect.description.line3')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConnectMobile;
