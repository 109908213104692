import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ReconvertTo3D } from '../../common/pricing/convert_dicom_ligth_bgd.svg';
import { ReactComponent as Documentation } from '../../common/pricing/documentation_ligth_bgd.svg';
import { ReactComponent as SmileDesign } from '../../common/pricing/design_ligth_bgd.svg';
import { ReactComponent as CrossSection } from '../../common/pricing/cross_ligth_bgd.svg';

const useFeatures = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const features = {
      starter: {
        common: [
          {
            name: t('v2.pricing.feature.allStandardFeatures.name'),
            examples: [
              {
                iconComponent: Documentation,
                name: t(
                  'v2.pricing.feature.allStandardFeatures.cloudDocumentationLibrary'
                )
              },
              {
                iconComponent: SmileDesign,
                name: t(
                  'v2.pricing.feature.allStandardFeatures.unlimitedRealTimeSmileDesigns'
                )
              },
              {
                iconName: 'fingerprint',
                name: t(
                  'v2.pricing.feature.allStandardFeatures.unlimitedBiometricLibraries'
                )
              },
              {
                iconName: 'forum',
                name: t(
                  'v2.pricing.feature.allStandardFeatures.virtualTreatmentRoom'
                )
              },
              {
                iconName: 'view_in_ar',
                name: t(
                  'v2.pricing.feature.allStandardFeatures.stackAndAlignData'
                )
              },
              {
                iconName: 'assignment',
                name: t(
                  'v2.pricing.feature.allStandardFeatures.patientDataPassports'
                )
              }
            ]
          }
        ]
      },
      business: [
        {
          name: t('v2.pricing.feature.teamWorkspace.name'),
          examples: [
            {
              iconName: 'folder_shared',
              name: t(
                'v2.pricing.feature.teamWorkspace.onePlaceForAllTeamCases'
              )
            },
            {
              iconName: 'manage_accounts',
              name: t('v2.pricing.feature.teamWorkspace.setMembersPermission')
            },
            {
              iconName: 'person_add',
              name: t('v2.pricing.feature.teamWorkspace.addMoreTeamMembers')
            }
          ]
        },
        {
          name: t('v2.pricing.feature.powerfulTools.name'),
          examples: [
            {
              iconComponent: ReconvertTo3D,
              name: t('v2.pricing.feature.powerfulTools.convertDicomTo3D')
            },
            {
              iconComponent: CrossSection,
              name: t('v2.pricing.feature.powerfulTools.dynamicCbct')
            },
            {
              iconName: 'play_arrow',
              name: t('v2.pricing.feature.powerfulTools.reviewModjaw')
            }
          ]
        },
        {
          name: t('v2.pricing.feature._365DaysFileRecovery.name'),
          examples: [
            {
              iconName: 'history',
              name: t('v2.pricing.feature._365DaysFileRecovery.restoreAnyCase')
            }
          ]
        }
      ],
      enterprise: [
        {
          name: t('v2.pricing.feature.dedicatedSupportAndOnBoarding.name')
        },
        {
          name: t('v2.pricing.feature.onDemandCustomizations.name')
        },
        {
          name: t('v2.pricing.feature._5YearsFileRecovery.name')
        }
      ]
    };

    features.starter.monthly = [
      ...features.starter.common,
      {
        name: t('v2.pricing.feature.fourteenDaysRecovery.name'),
        examples: [
          {
            iconName: 'history',
            name: t('v2.pricing.feature.fourteenDaysRecovery.restoreAnyCase')
          }
        ]
      }
    ];

    features.starter.annually = [
      ...features.starter.common,
      {
        name: t('v2.pricing.feature.fourteenDaysRecovery.name'),
        examples: [
          {
            iconName: 'history',
            name: t('v2.pricing.feature.fourteenDaysRecovery.restoreAnyCase')
          }
        ]
      }
    ];

    return features;
  }, [t]);
};

export { useFeatures };
