import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import secure from '../resources/patient_secure.png';
import secure1X from '../resources/patient_secure@2x.png';
import secureSVG from '../resources/svg/secure.svg';

import './patientContent.scss';

class PatientSecure extends Component {
  render() {
    const { t } = this.props;
    return (
      <section className="patient-ea ea-full-width">
        <div className="easy-access">
          <div className="ea-row">
            <img
              className="ea-main-image"
              srcSet={`${secure1X} 648w, ${secure} 1296w`}
              src={secure1X}
              alt="Secure"
            />

            <div className="ea-message-container">
              <div className="ea-message-title">
                <img
                  src={secureSVG}
                  className="ea-message-title-icon"
                  alt={t('patient.secure.title1')}
                />
                {t('patient.secure.title1')}
                <br />
                {t('patient.secure.title2')}
              </div>

              <div className="ea-message-body">
                <p>{t('patient.secure.info1')}</p>

                <p>{t('patient.secure.info2')}</p>

                <p>{t('patient.secure.info3')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(PatientSecure);
