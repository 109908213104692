import React from 'react';
import { useTranslation } from 'react-i18next';

import functional from '../../common/metalab/functional@2x.png';
import functional1X from '../../common/metalab/functional.png';

import metalab from '../../common/metalab/metalab@2x.png';
import metalab1X from '../../common/metalab/metalab.png';

import './Guiding.scss';

const Guiding = () => {
  const { t } = useTranslation();

  return (
    <section className="mg-full-width">
      <div className="metalab-guiding mg-content">
        <div className="mg-row">
          <div className="mg-data-container-wrapper">
            <div className="mg-data-container">
              <img
                srcSet={`${functional1X} 648w, ${functional} 1296w`}
                src={functional1X}
                className="mg-main-image"
                alt={t('metalab.ready.print.functional.title')}
              />
              <div className="mg-ready-print-container">
                <span className="mg-ready-print-title">
                  {t('metalab.ready.print.functional.title')}
                </span>
                <img
                  srcSet={`${metalab1X} 648w, ${metalab} 1296w`}
                  src={metalab}
                  className="mg-ready-print-image"
                  alt={t('easyAccess.main.menu.connect')}
                />
              </div>
            </div>
          </div>
          <div className="mg-message-container">
            <div className="mg-message-title">{t('metalab.guide.title')}</div>

            <div className="mg-message-body">
              <p>{t('metalab.guide.description.line1')}</p>

              <p>{t('metalab.guide.description.line2')}</p>

              <p>{t('metalab.guide.description.line3')}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Guiding;
