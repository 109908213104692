import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import './theme.scss';
import Cloud from './Cloud';
import SmileDesign from './SmileDesign';
import Connect from './Connect';
import Review from './Review';

import documentation from '../../common/illustrations/documentation.svg';
import review from '../../common/illustrations/review.svg';
import design from '../../common/illustrations/design.svg';
import connect from '../../common/illustrations/connect.svg';

class EasyAccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0
    };
  }

  changeTab = newActive => {
    window.fp.moveTo(2, 0);
    this.setState({ active: newActive });
  };

  render() {
    const { t } = this.props;
    const { active } = this.state;
    let cssCarousel = 'ea-carousel';
    if (active === 1) {
      cssCarousel = cssCarousel + ' two';
    }

    if (active === 2) {
      cssCarousel = cssCarousel + ' three';
    }

    if (active === 3) {
      cssCarousel = cssCarousel + ' four';
    }

    return (
      <section id="easyaccess">
        <div className="ea-menu">
          <div
            className="ea-menu-item"
            onClick={() => this.changeTab(0)}
            data-active={active === 0 ? true : false}
          >
            <img
              style={{ height: '55px', marginBottom: '10px' }}
              src={design}
              className="ea-menu-item-ico ea-bigger"
              alt={t('easyAccess.main.menu.smile')}
            />
            <span>{t('easyAccess.main.menu.smile')}</span>
          </div>

          <div
            className="ea-menu-item"
            onClick={() => this.changeTab(1)}
            data-active={active === 1 ? true : false}
          >
            <img
              style={{ height: '55px', marginBottom: '10px' }}
              src={review}
              className="ea-menu-item-icon ea-bigger"
              alt={t('easyAccess.main.menu.review')}
            />
            <span style={{ textAlign: 'center' }}>
              {t('easyAccess.main.menu.review')}
            </span>
          </div>

          <div
            className="ea-menu-item"
            onClick={() => this.changeTab(2)}
            data-active={active === 2 ? true : false}
          >
            <img
              src={documentation}
              className="ea-menu-item-icon"
              alt={t('easyAccess.main.menu.cloud')}
            />
            <span>{t('easyAccess.main.menu.cloud')}</span>
          </div>

          <div
            className="ea-menu-item"
            onClick={() => this.changeTab(3)}
            data-active={active === 3 ? true : false}
          >
            <img
              src={connect}
              className="ea-menu-item-icon ea-menu-item-icon-bigger"
              alt={t('easyAccess.main.menu.connect')}
            />
            <span>{t('easyAccess.main.menu.connect')}</span>
          </div>
        </div>

        <div className={cssCarousel}>
          <SmileDesign onLoad={this.props.onSmileLoad} />
          <Review onLoad={this.props.onReviewLoad} />
          <Cloud />
          <Connect />
        </div>
      </section>
    );
  }
}

export default withTranslation()(EasyAccess);
