import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGES, findGetParameter } from './common/constants';

import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';

let lang = (
  (navigator.languages && navigator.languages[0]) ||
  navigator.userLanguage ||
  navigator.language
)
  .replace('-', '_')
  .toLowerCase()
  .split('_')[0];
lang = findGetParameter('lang') || lang;
const langsArr = LANGUAGES.map(lang => lang.value);
const browserLanguage = langsArr.indexOf(lang) === -1 ? 'en' : lang;

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: browserLanguage,
    fallbackLng: 'en',
    debug: true,
    react: { useSuspense: true },
    returnObjects: true,

    backend: {
      // for all available options read the backend's repository readme file
      //   loadPath: `${API_URL}/locales/www/{{lng}}/{{ns}}.json`,
      loadPath: function(lngs, namespaces) {
        return `${process.env.REACT_APP_PHRASE_CLOUDFRONT_URL}/www/${lngs[0]}.json`;
      },
      crossDomain: true,
      customHeaders: {
        'Cache-Control': 'no-cache'
      }
    },

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18n;
