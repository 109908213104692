import React from 'react';
import './theme.scss';

import logoImage from '../common/img/logo_full.svg';
import logoLightImage from '../common/img/logo_full_light.svg';

const Logo = ({ theme, size, logoSrcProp }) => {
  const wrapperCssClass = `logoWrapper ${size ? size : ''}`;
  const iconCssClass = `logo ${size ? size : ''}`;
  let src = theme === 'dark' ? logoLightImage : logoImage;

  if (logoSrcProp) {
    src = logoSrcProp;
  }

  return (
    <span className={wrapperCssClass}>
      <img src={src} className={iconCssClass} alt="logo" />
    </span>
  );
};

export default Logo;
