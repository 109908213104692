import React from 'react';
import StandardFeature from './StandardFeature';
import { useTranslation } from 'react-i18next';
import cloudDocumentationImg from '../../../common/pricing/documentation@2x.png';
import unlimitedRealTimeSmileDesignsImg from '../../../common/pricing/design@2x.png';
import unlimitedBiometricLibrariesImg from '../../../common/pricing/libraries@2x.png';
import virtualTreatmentRoomImg from '../../../common/pricing/VTR@2x.png';
import stackDataImg from '../../../common/pricing/review@2x.png';
import patientDataPassportImg from '../../../common/pricing/passport@2x.png';
import { ReactComponent as Documentation } from '../../../common/pricing/documentation_36px.svg';
import { ReactComponent as SmileDesign } from '../../../common/pricing/design_36px.svg';
import { ReactComponent as Passport } from '../../../common/pricing/passport_36px.svg';
import { ReactComponent as ThreeDReview } from '../../../common/pricing/3Dreview_36px.svg';
import { ReactComponent as Biometric } from '../../../common/pricing/biometric_36px.svg';
import { ReactComponent as VtrSvg } from '../../../common/pricing/VTR_36px.svg';
import './standardFeatures.scss';

const StandardFeatures = () => {
  const { t } = useTranslation();

  return (
    <article className="pricing__standard-features">
      <StandardFeature
        iconComponent={Documentation}
        title={t(
          'v2.pricing.subscription.includedStandardFeatures.cloudDocumentation'
        )}
        imgSrc={cloudDocumentationImg}
      />
      <StandardFeature
        iconComponent={SmileDesign}
        title={t(
          'v2.pricing.feature.allStandardFeatures.unlimitedRealTimeSmileDesigns'
        )}
        imgSrc={unlimitedRealTimeSmileDesignsImg}
      />
      <StandardFeature
        iconComponent={Biometric}
        title={t(
          'v2.pricing.feature.allStandardFeatures.unlimitedBiometricLibraries'
        )}
        imgSrc={unlimitedBiometricLibrariesImg}
      />
      <StandardFeature
        iconComponent={VtrSvg}
        title={t('v2.pricing.feature.allStandardFeatures.virtualTreatmentRoom')}
        imgSrc={virtualTreatmentRoomImg}
      />
      <StandardFeature
        iconComponent={ThreeDReview}
        title={t('v2.pricing.feature.allStandardFeatures.stackData')}
        imgSrc={stackDataImg}
      />
      <StandardFeature
        iconComponent={Passport}
        title={t('v2.pricing.feature.allStandardFeatures.patientDataPassports')}
        imgSrc={patientDataPassportImg}
      />
    </article>
  );
};

export default StandardFeatures;
