import React, { useCallback, useRef, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classnames from 'classnames';

import FontIcon from '../../vendor/font_icon';

import motivational from '../../common/metalab/motivational@2x.png';
import motivational1X from '../../common/metalab/motivational.png';

import metalab from '../../common/metalab/metalab@2x.png';
import metalab1X from '../../common/metalab/metalab.png';

import mockup from '../../common/metalab/mockup.mp4';
import mockupPoster from '../../common/metalab/mockup@2x.png';
import { useWindowSize } from '../../common/useWindowSize';

import './Experience.scss';

const Experience = () => {
  const { t } = useTranslation();
  const [videoPlaying, setVideoPlaying] = useState(false);
  const { isMobile } = useWindowSize();

  const videoRef = useRef(null);

  const onEnded = () => {
    setVideoPlaying(false);
  };

  const onClick = useCallback(() => {
    if (videoRef.current) {
      if (videoPlaying) {
        setVideoPlaying(false);
        videoRef.current.pause();
      } else {
        setVideoPlaying(true);
        videoRef.current.play();
      }
    }
  }, [videoPlaying]);

  return (
    <section className="de-full-width">
      <div className="design-experience de-content">
        <div className="de-row">
          <div className="de-message-container">
            <div className="de-message-title">
              {t('metalab.experience.title')}
            </div>

            <div className="de-message-body">
              <p>{t('metalab.experience.description.v2.line1')}</p>
              {isMobile && (
                <MetalabVideo
                  videoRef={videoRef}
                  onClick={onClick}
                  onEnded={onEnded}
                  videoPlaying={videoPlaying}
                  className="forMobile"
                />
              )}

              <img
                srcSet={`${metalab1X} 648w, ${metalab} 1296w`}
                src={metalab}
                className="de-ready-print-image"
                alt={t('easyAccess.main.menu.connect')}
              />
              <p>
                <Trans
                  i18nKey={'metalab.ready.print.title'}
                  components={{
                    metalab: (
                      <LinkText
                        to={'https://www.metalab.one/'}
                        title="Metalab"
                      />
                    ),
                    dentcof: (
                      <LinkText
                        to={'https://www.dentcof.com/'}
                        title="Dentcof"
                      />
                    )
                  }}
                />
              </p>
            </div>
          </div>
          {!isMobile && (
            <MetalabVideo
              videoRef={videoRef}
              onClick={onClick}
              onEnded={onEnded}
              videoPlaying={videoPlaying}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Experience;

const LinkText = props => {
  return (
    <a
      href={props.to || '#'}
      target="_blank"
      rel="noopener noreferrer"
      title={props.title || ''}
    >
      {props.children}
    </a>
  );
};

const MetalabVideo = props => {
  const { t } = useTranslation();
  const videoSrc = mockup;
  const videoPosterSrc = mockupPoster;
  return (
    <div className={'de-data-container-wrapper'}>
      <div
        className={classnames('de-data-container', {
          [props.className]: !!props.className
        })}
      >
        <div className="de-video-container">
          <video
            ref={props.videoRef}
            poster={videoPosterSrc}
            className={'metalabVideo'}
            muted
            preload={'auto'}
            playsInline
            onClick={props.onClick}
            onEnded={props.onEnded}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
          <div className="de-video-control" onClick={props.onClick}>
            <FontIcon
              value={props.videoPlaying ? 'pause' : 'play_circle_outline'}
              className={`de-video-control-icon ${props.videoPlaying &&
                'playing'}`}
            />
          </div>
        </div>
        <img
          srcSet={`${motivational1X} 648w, ${motivational} 1296w`}
          src={motivational1X}
          className="de-main-image"
          alt={t('metalab.experience.title')}
        />
      </div>
    </div>
  );
};
