import React from 'react';
import { useTranslation } from 'react-i18next';
import { REGISTER_URL } from '../../common/constants';

import './theme.scss';

function Redefine() {
  const { t } = useTranslation();
  return (
    <section id="redefine-section">
      <div className="redefine-row">
        <span className="mf-small-text">{t('redefine.title')}</span>
        <span className="redefine-big-hero-message">
          {t('dentist.hero.title')}
        </span>
      </div>
      <div className="mf-row">
        <a href={REGISTER_URL} className="as-link dark hero">
          <div
            style={{ maxWidth: '20rem' }}
            className="button primary main mobile-hero"
          >
            {t('redefine.start')}
          </div>
        </a>
      </div>
    </section>
  );
}

export default Redefine;
