import React, { Component, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import './App.scss';

import Patient from './patient';
import Pricing from './pricing';

import Home from './home';
import Download from './download/Download';

// import i18n (needs to be bundled ;))
import './i18n';

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

class App extends Component {
  render() {
    return (
      <Suspense
        fallback={
          <div
            style={{
              width: '100vw',
              height: '100vh',
              backgroundColor: 'black'
            }}
          ></div>
        }
      >
        <Router>
          <div className="App container-fluid">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/download" component={Download} />
              <Route path="/pricing" component={Pricing} />
              <Route path="/signin" component={SignIn} />
              <Route path="/signup" component={SignUp} />
              <Route path="/patient" component={Patient} />
              <Route path="/contact" component={Contact} />
              <Redirect to="/" />
            </Switch>
          </div>
        </Router>
      </Suspense>
    );
  }
}

const SignIn = () => (
  <section id="signin">
    <div className="centered" style={{ minHeight: '80vh' }} />
  </section>
);

const SignUp = () => (
  <section id="signup">
    <div className="row">Sign Up</div>
  </section>
);

const Contact = () => (
  <section id="pricing">
    <div className="row">Contact Us</div>
  </section>
);

export default App;
