import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SubscriptionFeature, {
  subscriptionFeatureProps
} from '../features/subscription/SubscriptionFeature';
import './priceCard.scss';

const PriceCard = props => {
  const { t } = useTranslation();

  return (
    <section
      className={classNames('price-card', {
        'price-card--transparent': props.transparent
      })}
    >
      <div className="price-card__title-container">
        <h2 className="price-card__title">{props.title}</h2>
        <span
          className={classNames('price-card__title-most-popular', {
            'price-card__title-most-popular--hidden': !props.mostPopular
          })}
        >
          {t('v2.pricing.mostPopular')}
        </span>
      </div>
      <p className="price-card__subtitle">{props.subtitle}</p>
      <p
        className={classNames('price-card__price-wrapper', {
          'price-card__price-wrapper--hidden': !props.price
        })}
      >
        <span className="price-card__price">{props.price}</span>&nbsp;
        <span className="price-card__period">{props.pricePeriod}</span>
        &nbsp;
        <span
          className={classNames({
            'price-card__billed-annually': props.billedAnnually,
            'price-card__billed-monthly': !props.billedAnnually
          })}
        >
          {t('v2.pricing.subscriptions.billedAnnually')}
        </span>
      </p>
      <p>
        {props.users} *&nbsp;
        {props.unlimitedCases ? (
          <span className="price-card__unlimited-cases">
            {t('v2.pricing.unlimitedCases')}
          </span>
        ) : (
          <span>{props.cases}</span>
        )}
      </p>
      {props.button}
      <p className="price-card__features-title">{props.featuresTitle}</p>
      <div>
        {props.features.map((feature, i) => (
          <SubscriptionFeature key={i} feature={feature} />
        ))}
      </div>
    </section>
  );
};

PriceCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.string,
  pricePeriod: PropTypes.string,
  users: PropTypes.string,
  cases: PropTypes.string,
  unlimitedCases: PropTypes.bool,
  billedAnnually: PropTypes.bool,
  featuresTitle: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.shape(subscriptionFeatureProps)),
  button: PropTypes.object,
  transparent: PropTypes.bool,
  mostPopular: PropTypes.bool
};

export default PriceCard;
