import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import videoPosterSrc from '../../common/illustrations/biometric_design_tab_ok.jpg';
import videoSrc from '../../common/illustrations/biometric_design_tab_ok.mp4';
import design from '../../common/illustrations/design.svg';

import './theme.scss';

class SmileDesign extends Component {
  componentDidMount() {
    if (this.video) {
      const { onLoad } = this.props;
      if (onLoad) onLoad(this.video);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <section className="ea-full-width">
        <div className="easy-access">
          <div className="ea-row">
            <div className="ea-header">
              <img
                src={design}
                className="ea-title-icon"
                alt={t('easyAccess.main.menu.smile')}
              />
              {t('easyAccess.main.menu.smile')}
            </div>
            <video
              poster={videoPosterSrc}
              ref={ref => {
                this.video = ref;
              }}
              className={'main-video'}
              muted
              preload={'auto'}
              playsInline
              autoPlay
              loop
            >
              <source src={videoSrc} type="video/mp4" />
            </video>
            <div className="ea-message-container">
              <div className="ea-message-title">
                {t('easyAccess.smileDesign.title')}
              </div>

              <div className="ea-message-body">
                <p>{t('easyAccess.smileDesign.description.line1')}</p>

                <p>{t('easyAccess.smileDesign.description.line2')}</p>

                <p>{t('easyAccess.smileDesign.description.line3')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(SmileDesign);
