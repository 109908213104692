import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import control from '../resources/patient_control.png';
import control1X from '../resources/patient_control@2x.png';
import controlSVG from '../resources/svg/control.svg';

import './patientContent.scss';

class PatientControl extends Component {
  render() {
    const { t } = this.props;
    return (
      <section className="patient-ea ea-full-width">
        <div className="easy-access">
          <div className="ea-row">
            <img
              className="ea-main-image"
              srcSet={`${control1X} 648w, ${control} 1296w`}
              src={control1X}
              alt="Control"
            />

            <div className="ea-message-container">
              <div className="ea-message-title">
                <img
                  src={controlSVG}
                  className="ea-message-title-icon"
                  alt={t('patient.control.title2')}
                />
                {t('patient.control.title1')}
                <br />
                {t('patient.control.title2')}
              </div>

              <div className="ea-message-body">
                <p>{t('patient.control.info1')}</p>

                <p>{t('patient.control.info2')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(PatientControl);
