import React from 'react';
import PatientHero from './patientHero/patientHero';
import ScrollToTopOnMount from '../scrollToTopOnMount';
import PatientHeader from './patientHeader/patientHeader';
import PatientSecure from './patientContents/patientSecure';
import PatientControl from './patientContents/patientControl';
import PatientPassport from './patientContents/patientPassport';
import PatientFooter from './patientFooter/patientFooter';
import { useWindowSize } from '../common/useWindowSize';

const Patient = ({ isMobile }) => {
  return (
    <section id="home">
      <ScrollToTopOnMount />
      <PatientHeader
        isMobile={isMobile}
        theme="light"
        fixed={true}
        size="small"
      />

      <div id="fullpage">
        <div className="section" id="section1">
          <PatientHero isMobile={isMobile} />
        </div>

        <div className="section fp-auto-height" id="section2">
          <PatientSecure />
        </div>

        <div className="section fp-auto-height" id="section3">
          <PatientControl />
        </div>

        <div className="section fp-auto-height" id="section5">
          <PatientPassport isMobile={isMobile} />
        </div>

        <div className="section fp-auto-height" id="section7">
          <PatientFooter theme="transparent" />
        </div>
      </div>
    </section>
  );
};

function Wrapper() {
  const { isMobileOrTablet } = useWindowSize();
  return <Patient isMobile={isMobileOrTablet} />;
}

export default Wrapper;
