import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './outsourceFeature.scss';

const OutsourceFeature = props => {
  const { t } = useTranslation();

  return (
    <section className="pricing__outsource-feature">
      <h3 className="pricing__outsource-feature-title">{props.title}</h3>
      <p className="pricing__outsource-feature-subtitle">{props.subtitle}</p>
      <div className="pricing__outsource-feature-price-container">
        <span>
          <span className="pricing__outsource-feature-price">
            &euro;{props.price}
          </span>
          <span className="pricing__outsource-feature-unit">
            &nbsp;/{props.priceUnit || t('price.biometric.arch')}
          </span>
        </span>
        <span className="pricing__outsource-feature-ready-to">
          {props.readyTo || t('v2.pricing.outsource.readyToPrint')}
        </span>
      </div>
      <img
        className="pricing__outsource-feature-image"
        src={props.imgSrc}
        alt=""
      />
    </section>
  );
};

OutsourceFeature.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceUnit: PropTypes.string,
  readyTo: PropTypes.string,
  imgSrc: PropTypes.string.isRequired
};

export default OutsourceFeature;
