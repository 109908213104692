import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import heroSrcDesktop from '../resources/passport_hero_desktop@2x.png';
// import heroSrcMobile from '../resources/passport_hero_desktop.png'
import heroSrcMobile from '../resources/passport_hero_responsive@2x.png';
import enHero from '../resources/patient_hero/hero_patient_en_text@2x.png';
import deHero from '../resources/patient_hero/hero_patient_de_text@2x.png';
import esHero from '../resources/patient_hero/hero_patient_es_text@2x.png';
import frHero from '../resources/patient_hero/hero_patient_fr_text@2x.png';
import ptHero from '../resources/patient_hero/hero_patient_pt_text@2x.png';
import roHero from '../resources/patient_hero/hero_patient_ro_text@2x.png';
import trHero from '../resources/patient_hero/hero_patient_tr_text@2x.png';

import enHeroMobile from '../resources/patient_hero/mobile/mobile_hero_patient_en_text@2x.png';
import deHeroMobile from '../resources/patient_hero/mobile/mobile_hero_patient_de_text@2x.png';
import esHeroMobile from '../resources/patient_hero/mobile/mobile_hero_patient_es_text@2x.png';
import frHeroMobile from '../resources/patient_hero/mobile/mobile_hero_patient_fr_text@2x.png';
import ptHeroMobile from '../resources/patient_hero/mobile/mobile_hero_patient_pt_text@2x.png';
import roHeroMobile from '../resources/patient_hero/mobile/mobile_hero_patient_ro_text@2x.png';
import trHeroMobile from '../resources/patient_hero/mobile/mobile_hero_patient_tr_text@2x.png';

import './patientHero.scss';

const getTextImage = (isMobile, i18n) => {
  const language = i18n.language;

  if (language === 'en') {
    if (isMobile) {
      return enHeroMobile;
    }

    return enHero;
  }

  if (language === 'de') {
    if (isMobile) {
      return deHeroMobile;
    }

    return deHero;
  }

  if (language === 'es') {
    if (isMobile) {
      return esHeroMobile;
    }

    return esHero;
  }

  if (language === 'fr') {
    if (isMobile) {
      return frHeroMobile;
    }

    return frHero;
  }

  if (language === 'pt') {
    if (isMobile) {
      return ptHeroMobile;
    }

    return ptHero;
  }

  if (language === 'ro') {
    if (isMobile) {
      return roHeroMobile;
    }

    return roHero;
  }

  if (language === 'tr') {
    if (isMobile) {
      return trHeroMobile;
    }

    return trHero;
  }

  if (isMobile) {
    return enHeroMobile;
  }

  return enHero;
};

class PatientHero extends Component {
  constructor(props) {
    super(props);

    this.state = {
      oldX: 0,
      oldY: 0
    };
  }

  componentDidMount() {
    const { onLoad } = this.props;
    if (this.video) {
      this.video.controls = false;
      this.video.width = '100vw';
      this.video.play();
    }

    if (onLoad) onLoad(this.video);
  }

  onOverlayClick = () => {
    if (this.video) {
      this.video.play();
    }
  };

  render() {
    const { isMobile, i18n, t } = this.props;
    const imgSRC = isMobile ? heroSrcMobile : heroSrcDesktop;
    const textSRC = getTextImage(isMobile, i18n);

    return (
      <div className="container black">
        <img className="hero_img" src={imgSRC} alt="passport_hero_dekstop@2x" />

        <div className="hero_img_text_container">
          <img
            className="hero_img hero_img_text no_background"
            src={textSRC}
            alt="passport_hero_dekstop_text@2x"
          />
        </div>

        <div className="overlay" onClick={this.onOverlayClick}>
          <div className="hero-message">
            <div className="big-hero-message">{t('patient.hero.title')}</div>
            <div className="small-hero-message">{t('patient.hero.info')}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PatientHero);
