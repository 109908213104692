import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../header';
import Footer from '../footer';
import PriceCards from './price-cards/PriceCards';
import StandardFeatures from './features/standard/StandardFeatures';
import BusinessFeatures from './features/business/BusinessFeatures';
import Outsource from './features/outsourced/Outsource';
import './pricing.scss';
import Faqs from './faq/Faqs';
import Comparison from './comparison/Comparison';
import PricingButton from './shared/button/PricingButton';
import { useWindowSize } from '../common/useWindowSize';

const Pricing = ({ location }) => {
  const { t } = useTranslation();
  const [subInterval, setSubInterval] = useState('annually');
  const { isMobile, isMobileOrTablet } = useWindowSize();

  return (
    <div className="pricing__container">
      <Header
        theme="light"
        isMobile={isMobileOrTablet}
        fixed
        opaque={isMobile}
      />
      <main className="pricing__main">
        <div className="pricing__heading-main-container">
          <h1 className="pricing__heading pricing__heading-main">
            {t('dentist.hero.v2.title')}
          </h1>
        </div>
        <PriceCards interval={subInterval} onChangeInterval={setSubInterval} />
        <Comparison />
        <h2 className="pricing__heading">
          {t('v2.pricing.includedStandardFeatures')}
        </h2>
        <StandardFeatures />
        <h2 className="pricing__heading">
          {t('v2.pricing.businessPlanFeatures')}
        </h2>
        <BusinessFeatures />
        <Outsource />
        <h2 className="pricing__heading">{t('price.faq.title')}</h2>
        <Faqs />
        <div className="pricing__heading-main-container pricing__heading-main-container-bottom">
          <h1 className="pricing__heading pricing__heading-main">
            {t('dentist.hero.v2.title')}
          </h1>
          <PricingButton
            variant="primary"
            label={t('v2.pricing.subscription.trialDays')}
          />
        </div>
      </main>
      <Footer theme="light" />
    </div>
  );
};

export default Pricing;
