import React, { useEffect, useRef } from 'react';
import './tooltip.scss';

const Tooltip = props => {
  const tooltipRef = useRef();

  useEffect(() => {
    const size = tooltipRef.current?.getBoundingClientRect();
    tooltipRef.current.style.top = `-${size.height / 2 - 8}px`;
  }, []);

  return (
    <div className="tooltip">
      {props.trigger}
      <span ref={tooltipRef} className="tooltip-text">
        {props.children}
      </span>
    </div>
  );
};

export default Tooltip;
