import React from 'react';
import PropTypes from 'prop-types';
import FontIcon from '../../vendor/font_icon';

const Icon = props => {
  return (
    <>
      {props.iconComponent && (
        <props.iconComponent className={props.className} />
      )}
      {props.iconName && (
        <FontIcon className={props.className} value={props.iconName} />
      )}
    </>
  );
};

Icon.propTypes = {
  iconName: PropTypes.string,
  iconComponent: PropTypes.object
};

export default Icon;
