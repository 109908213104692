import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { PASSPORT_LOGIN_URL, TEXTS, LANGUAGES } from '../../common/constants';

import './patientHeader.scss';

import Logo from '../../logo';
import logoSrc from '../resources/logo_passport@2x.png';
import burgerMenuSVG from '../../common/illustrations/menu.svg';
import closeSVG from '../../common/illustrations/close.svg';
import LanguagesDropdown from '../../header/LanguagesDropdown';
import languageIcon from '../../common/illustrations/language.svg';
import chevronLeftIcon from '../../common/illustrations/chevron_left.svg';

export class PatientHeader extends Component {
  state = {
    showMenu: false,
    showLanguageSelector: false
  };

  showHelp = () => {
    const zendesk = window.zE;
    if (zendesk) {
      zendesk.activate();
    }
  };

  toggleMenu = () => {
    if (this.state.showMenu) {
      this.setState({ showMenu: false });
      document.body.style.overflowY = 'hidden';
      return;
    }

    this.setState({ showMenu: true });
    document.body.style.overflowY = 'auto';
  };

  toggleLanguageSelector = () => {
    this.setState({
      ...this.state,
      showLanguageSelector: !this.state.showLanguageSelector
    });
  };

  changeLanguage = lang => {
    const { i18n } = this.props;
    i18n.changeLanguage(lang.value);
    this.forceUpdate();
    this.setState({ ...this.state, showLanguageSelector: false });
  };

  render() {
    const { theme, isMobile, fixed, size, register, t, i18n } = this.props;
    const cssHeader = fixed ? 'fixed' : '';
    const languageLabel = LANGUAGES.find(({ value }) => value === i18n.language)
      .label;
    return (
      <section id="header" className={cssHeader}>
        <div
          className={`mobile_menu_container ${this.state.showMenu &&
            `display`}`}
        >
          <div className="mobile_menu_option">
            <NavLink
              style={{ color: 'white' }}
              to="/"
              className={'as-link with-space ' + theme}
            >
              {t('common.iDentist')}
            </NavLink>
          </div>

          <div className="mobile_menu_option">
            <a
              href={`https://blog.smilecloud.com?lang=${i18n.language}`}
              target="_blank"
              rel="noopener noreferrer"
              className={'as-link with-space ' + theme}
            >
              {t(TEXTS.blog)}
            </a>
          </div>

          <div className="mobile_menu_option">
            <p
              style={{ paddingTop: 0, margin: 0 }}
              onClick={this.showHelp}
              rel="noopener noreferrer"
              className={'as-link with-space ' + theme}
            >
              {t(TEXTS.contactUs)}
            </p>
          </div>

          <div className="mobile_menu_option">
            <a
              href={`${PASSPORT_LOGIN_URL}?lang=${i18n.language}`}
              className={'as-link with-space ' + theme}
            >
              {t(TEXTS.login)}
            </a>
          </div>

          <div
            className="mobile_menu_option"
            onClick={this.toggleLanguageSelector}
          >
            <div className="mobile_language_container">
              <img src={languageIcon} className="img" alt="language-icon" />
              <div className="language_label">{languageLabel}</div>
              <div className="arrow-down" />
            </div>
          </div>
        </div>

        <div
          className={`mobile_languages_selector ${this.state
            .showLanguageSelector && `display`}`}
        >
          <div className="header">
            <img
              onClick={this.toggleLanguageSelector}
              src={chevronLeftIcon}
              className="chevron_left"
              alt="back-icon"
            />
            <h2 className="header_title">Select your language</h2>
          </div>

          {LANGUAGES.map(language => (
            <div
              key={language.value}
              onClick={() => this.changeLanguage(language)}
              className={`language_option ${i18n.language === language.value &&
                `active`}`}
            >
              {language.label}
            </div>
          ))}
        </div>

        <div className={'header'}>
          <div className="row">
            <div className="left middle">
              <NavLink to="/patient" className="as-link">
                <Logo
                  logoSrcProp={logoSrc}
                  size={size ? size : theme === 'dark' ? 'small' : null}
                  theme={theme}
                />
              </NavLink>
            </div>
            {isMobile ? (
              <div className="burger-container">
                <img
                  onClick={this.toggleMenu}
                  style={{ height: '1.6rem' }}
                  src={this.state.showMenu ? closeSVG : burgerMenuSVG}
                  alt="hamburger"
                />
              </div>
            ) : register === true ? (
              <div className="right">
                <ul className="pullRight">
                  {/* <li className="pricing">
                    <NavLink to="/pricing" className={'as-link ' + theme}>
                      {TEXTS.pricing}
                    </NavLink>
                  </li> */}
                </ul>
              </div>
            ) : (
              <div className="right">
                <ul className="pullRight">
                  <li className="pricing">
                    <NavLink
                      style={{ color: 'white' }}
                      to="/"
                      className={'as-link with-space ' + theme}
                    >
                      {t('common.iDentist')}
                    </NavLink>
                  </li>
                  <li className="blog">
                    <a
                      href={`https://blog.smilecloud.com?lang=${i18n.language}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={'as-link with-space ' + theme}
                    >
                      {t(TEXTS.blog)}
                    </a>
                  </li>
                  <li className="blog">
                    <p
                      style={{ paddingTop: 0, paddingBottom: 0, margin: 0 }}
                      onClick={this.showHelp}
                      rel="noopener noreferrer"
                      className={'as-link with-space ' + theme}
                    >
                      {t(TEXTS.contactUs)}
                    </p>
                  </li>

                  <li>
                    <LanguagesDropdown i18n={i18n} theme={theme} />
                  </li>

                  <li className="signin">
                    <a
                      href={`${PASSPORT_LOGIN_URL}?lang=${i18n.language}`}
                      className={'as-link with-space ' + theme}
                    >
                      {t(TEXTS.login)}
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

PatientHeader.propTypes = {
  theme: PropTypes.string,
  fixed: PropTypes.bool
};

PatientHeader.defaultProps = {
  theme: 'dark',
  fixed: false
};

export default withTranslation()(PatientHeader);
