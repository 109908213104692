import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './pricingButton.scss';

const PricingButton = props => {
  return (
    <button
      onClick={props.onClick}
      className={classnames(props.className, 'pricing-button', {
        'pricing-button__primary': props.variant === 'primary',
        'pricing-button__outlined': props.variant === 'outlined',
        'pricing-button__wide': props.fullWidth
      })}
    >
      {props.label}
    </button>
  );
};

PricingButton.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['primary', 'outlined']),
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func
};

PricingButton.defaultProps = {
  variant: 'primary'
};

export default PricingButton;
