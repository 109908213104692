import React from 'react';
import PropTypes from 'prop-types';
import './businessFeature.scss';
import FeatureTitle from '../../shared/feature-title/FeatureTitle';

const BusinessFeature = props => {
  return (
    <section className="pricing__business-feature">
      <div className="pricing__business-feature-descriptions">
        <FeatureTitle
          title={props.title}
          iconName={props.iconName}
          iconComponent={props.iconComponent}
        />
        <ul className="pricing__business-feature-list">
          {props.descriptions.map((description, i) => (
            <li key={i}>{description}</li>
          ))}
        </ul>
      </div>
      <img
        className="pricing__business-feature-image"
        src={props.imgSrc}
        alt=""
      />
    </section>
  );
};

BusinessFeature.propTypes = {
  iconName: PropTypes.string,
  iconComponent: PropTypes.object,
  title: PropTypes.string.isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  imgSrc: PropTypes.string.isRequired
};

export default BusinessFeature;
