import React from 'react';
import { withTranslation } from 'react-i18next';

import HomeDesktop from './homeDesktop';
import HomeMobile from './homeMobile';
import { useWindowSize } from '../common/useWindowSize';

import './theme.scss';

function Home() {
  const { isMobileOrTablet } = useWindowSize();

  return isMobileOrTablet ? <HomeMobile /> : <HomeDesktop />;
}

export default withTranslation()(Home);
