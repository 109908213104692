import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../shared/tooltip/Tooltip';
import './subscriptionFeature.scss';
import Icon from '../../shared/Icon';
import { ReactComponent as InfoSvg } from '../../../common/pricing/info.svg';

const SubscriptionFeature = props => {
  const examples = useMemo(
    () =>
      props.feature.examples?.map((example, i) => (
        <div key={i} className="subscription__feature-example">
          <Icon
            className="subscription__feature-icon"
            iconName={example.iconName}
            iconComponent={example.iconComponent}
          />
          <span className="subscription__feature-text">{example.name}</span>
        </div>
      )),
    [props.feature.examples]
  );
  return (
    <div className="subscription__feature">
      <span className="subscription__feature-name">{props.feature.name}</span>
      {props.feature.examples && (
        <div className="subscription__feature-tooltip-desktop">
          <Tooltip trigger={<InfoSvg />}>{examples}</Tooltip>
        </div>
      )}
      <div className="subscription__feature-tooltip-mobile">{examples}</div>
    </div>
  );
};

const subscriptionFeatureProps = {
  feature: PropTypes.shape({
    name: PropTypes.string,
    examples: PropTypes.arrayOf(
      PropTypes.shape({
        iconName: PropTypes.string,
        iconComponent: PropTypes.object,
        name: PropTypes.string
      })
    )
  })
};

SubscriptionFeature.propTypes = subscriptionFeatureProps;

export { subscriptionFeatureProps };
export default SubscriptionFeature;
