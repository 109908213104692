import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './collapsible.scss';
import Icon from '../../shared/Icon';

const Collapsible = props => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  const iconName = isOpen ? 'keyboard_arrow_down' : 'keyboard_arrow_right';

  useEffect(() => {
    if (isOpen) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = '0px';
    }
  }, [isOpen]);

  return (
    <section className="collapsible__container">
      <div className="collapsible__header-container">
        <button
          className="collapsible__header"
          onClick={() => setIsOpen(!isOpen)}
        >
          {props.title}
        </button>
        <Icon className="collapsible__header-icon" iconName={iconName} />
      </div>
      <div ref={contentRef} className="collapsible__content-container">
        <p className="collapsible__content">{props.content}</p>
      </div>
    </section>
  );
};

Collapsible.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default Collapsible;
