import React from 'react';
import FullComparisonRow from './FullComparisonRow';
import { useComparisonData } from './useComparisonData';
import './fullComparisonBody.scss';

const FullComparisonBody = () => {
  const comparisons = useComparisonData();

  return (
    <>
      <tbody className="pricing__comparison-table-body">
        {comparisons.map((comparison, i) => (
          <FullComparisonRow
            key={i}
            name={comparison.name}
            starter={comparison.starter}
            business={comparison.business}
            enterprise={comparison.enterprise}
            isSectionTitle={comparison.isSectionTitle}
          />
        ))}
      </tbody>
    </>
  );
};

export default FullComparisonBody;
