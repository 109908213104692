import React from 'react';
import { useTranslation } from 'react-i18next';
import OutsourceFeature from './OutsourceFeature';
import interdisciplinaryDesignImg from '../../../common/pricing/metalab-interdisciplinary@2x.png';
import motivationalDesignImg from '../../../common/pricing/metalab-motivational@2x.png';
import anteriorPlatformImg from '../../../common/pricing/metalab-platform@2x.png';
import crownLengtheningGuideImg from '../../../common/pricing/metalab-guide@2x.png';
import digitalRestorationsImg from '../../../common/pricing/metalab-restorations@2x.png';
import './outsourceFeatures.scss';

const OutsourceFeatures = () => {
  const { t, i18n } = useTranslation();

  return (
    <section className="pricing__outsource-features">
      <OutsourceFeature
        title={t('v2.pricing.outsource.interdisciplinaryDesign.name')}
        subtitle={t(
          'v2.pricing.outsource.interdisciplinaryDesign.diagnosticInterdisciplinaryPlanning'
        )}
        price={139}
        imgSrc={interdisciplinaryDesignImg}
      />
      <OutsourceFeature
        title={t('v2.pricing.outsource.motivationalDesign.name')}
        subtitle={t(
          'v2.pricing.outsource.motivationalDesign.enhancePatientExperience'
        )}
        price={139}
        imgSrc={motivationalDesignImg}
      />
      <OutsourceFeature
        title={t('v2.pricing.outsource.anteriorPlatform.name')}
        subtitle={t(
          'v2.pricing.outsource.anteriorPlatform.predictableDeprogramming'
        )}
        price={99}
        imgSrc={anteriorPlatformImg}
      />
      <OutsourceFeature
        title={t('v2.pricing.outsource.crownLengtheningGuide.name')}
        subtitle={t(
          'v2.pricing.outsource.crownLengtheningGuide.recontouringSoftTissueLevels'
        )}
        price={99}
        imgSrc={crownLengtheningGuideImg}
      />
      <OutsourceFeature
        title={t('v2.pricing.outsource.digitalRestorations.name')}
        subtitle={t(
          'v2.pricing.outsource.digitalRestorations.designedPreservingTheBiometry'
        )}
        price={35}
        priceUnit={t('common.element')}
        readyTo={t('v2.pricing.outsource.readyToMill')}
        imgSrc={digitalRestorationsImg}
      />
      <div className="pricing__outsource-features-blog-link">
        <a
          href={`https://blog.smilecloud.com?lang=${i18n.language}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('common.learnMore')}
        </a>
        <p>{t('common.onSmilecloudBlog')}</p>
      </div>
    </section>
  );
};

export default OutsourceFeatures;
