import React, { useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import './modal.scss';
import Icon from '../shared/Icon';

const Modal = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    document.querySelector('html').style.overflow = isOpen
      ? 'hidden'
      : 'scroll';
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = e => e.key === 'Escape' && onClose();
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  const closeModalFromBackdrop = e => e.target === e.currentTarget && onClose();

  return (
    <div
      className={classnames('modal__backdrop', {
        'modal__backdrop-open': isOpen
      })}
      onClick={closeModalFromBackdrop}
    >
      <section className="modal__content">
        <div className="modal__close" onClick={onClose}>
          <Icon iconName="close" />
        </div>
        {children}
      </section>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default Modal;
