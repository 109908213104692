import React from 'react';
import PropTypes from 'prop-types';
import './standardFeature.scss';
import FeatureTitle from '../../shared/feature-title/FeatureTitle';

const StandardFeature = props => {
  return (
    <section className="pricing__standard-feature">
      <FeatureTitle
        title={props.title}
        iconName={props.iconName}
        iconComponent={props.iconComponent}
      />
      <img
        className="pricing__standard-feature-image"
        src={props.imgSrc}
        alt=""
      />
    </section>
  );
};

StandardFeature.propTypes = {
  iconName: PropTypes.string,
  iconComponent: PropTypes.object,
  title: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired
};

export default StandardFeature;
