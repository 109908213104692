import React from 'react';
import { useTranslation } from 'react-i18next';
import './fullComparisonHead.scss';

const FullComparisonHead = () => {
  const { t } = useTranslation();

  return (
    <thead>
      <tr className="pricing__comparison-table-head-row">
        <th className="pricing__comparison-table-header">
          <p className="pricing__comparison-table-header-title">Starter</p>
          <span className="pricing__comparison-table-header-title-sub">
            {t('v2.pricing.subscriptions.starter.subtitle')}
          </span>
        </th>
        <th className="pricing__comparison-table-header">
          <p className="pricing__comparison-table-header-title">Business</p>
          <span className="pricing__comparison-table-header-title-sub">
            {t('v2.pricing.subscriptions.business.subtitle')}
          </span>
        </th>
        <th className="pricing__comparison-table-header">
          <p className="pricing__comparison-table-header-title">Enterprise</p>
          <span className="pricing__comparison-table-header-title-sub">
            {t('v2.pricing.subscriptions.enterprise.subtitle')}
          </span>
        </th>
      </tr>

      <tr className="pricing__comparison-table-head-row-sub">
        <th className="pricing__comparison-table-header-sub">
          {t('common.user', { count: 2 })}
        </th>
        <th className="pricing__comparison-table-header-sub">1</th>
        <th className="pricing__comparison-table-header-sub">2+</th>
        <th className="pricing__comparison-table-header-sub">50+</th>
        <td className="pricing__comparison-table-data-hover" />
      </tr>

      <tr className="pricing__comparison-table-head-row-sub">
        <th className="pricing__comparison-table-header-sub">
          {t('price.casesIncluded')}
        </th>
        <th className="pricing__comparison-table-header-sub">120</th>
        <th className="pricing__comparison-table-header-sub">
          {t('common.unlimited')}
        </th>
        <th className="pricing__comparison-table-header-sub">
          {t('common.unlimited')}
        </th>
        <td className="pricing__comparison-table-data-hover" />
      </tr>
    </thead>
  );
};

export default FullComparisonHead;
