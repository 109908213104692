import React, { useMemo } from 'react';
import BusinessFeature from './BusinessFeature';
import teamWorkspaceImg from '../../../common/pricing/business-team@2x.png';
import powerfulToolsImg from '../../../common/pricing/business-interdisciplinary@2x.png';
import { ReactComponent as PowerfulToolsSvg } from '../../../common/pricing/powerfull.svg';
import { useTranslation } from 'react-i18next';
import './businessFeatures.scss';

const BusinessFeatures = () => {
  const { t } = useTranslation();
  const descriptions = useMemo(
    () => ({
      teamWorkspace: [
        t('v2.pricing.feature.teamWorkspace.onePlaceForAllTeamCases'),
        t('v2.pricing.feature.teamWorkspace.setMembersPermission'),
        t('v2.pricing.feature.teamWorkspace.addMoreTeamMembers')
      ],
      powerfulTools: [
        t('v2.pricing.feature.powerfulTools.convertDicomTo3D'),
        t('v2.pricing.feature.powerfulTools.dynamicCbct'),
        t('v2.pricing.feature.powerfulTools.reviewModjaw')
      ]
    }),
    [t]
  );

  return (
    <article className="pricing__business-features">
      <BusinessFeature
        iconName="group_add"
        title={t('v2.pricing.feature.teamWorkspace.name')}
        descriptions={descriptions.teamWorkspace}
        imgSrc={teamWorkspaceImg}
      />
      <BusinessFeature
        iconComponent={PowerfulToolsSvg}
        title={t('v2.pricing.feature.powerfulTools.name')}
        descriptions={descriptions.powerfulTools}
        imgSrc={powerfulToolsImg}
      />
    </article>
  );
};

export default BusinessFeatures;
