import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './intervalRadio.scss';
import { useWindowSize } from '../../common/useWindowSize';

const IntervalRadio = props => {
  const { t } = useTranslation();
  const containerRef = useRef();
  const inputsRef = useRef();
  const annualRef = useRef();
  const monthlyRef = useRef();
  const underlineRef = useRef();
  const [isToggleSticky, setIsToggleSticky] = useState(false);
  const { isMobile } = useWindowSize();

  useEffect(() => {
    const inputsRect = inputsRef.current?.getBoundingClientRect();
    const annualRect = annualRef.current?.getBoundingClientRect();
    const monthlyRect = monthlyRef.current?.getBoundingClientRect();
    const initialRect = props.value === 'annually' ? annualRect : monthlyRect;
    const radioButtonWidth = 27;
    underlineRef.current.style.width = `${initialRect.width -
      radioButtonWidth}px`;
    underlineRef.current.style.left = `${initialRect.left -
      inputsRect.left +
      radioButtonWidth}px`;
  }, [props.value]);

  useEffect(() => {
    if (!isMobile) {
      return setIsToggleSticky(false);
    }
    const minPixelsFromTop = -1377;
    const maxPixelsFromTop = 122;
    const isWithinStickyRange =
      props.cardsPixelsFromTop > minPixelsFromTop &&
      props.cardsPixelsFromTop < maxPixelsFromTop;
    setIsToggleSticky(isWithinStickyRange);
  }, [props.cardsPixelsFromTop, isMobile]);

  return (
    <div ref={containerRef} className="pricing__interval-container">
      <div
        className={classnames({
          'pricing__interval-container-sticky': isToggleSticky
        })}
      >
        <form className="pricing__interval">
          <div ref={inputsRef} className="pricing__interval-inputs">
            <section className="pricing__interval-radio">
              <input
                id="pricing__annual-subscription-interval"
                type="radio"
                name="interval"
                value="annually"
                onChange={e => props.onChange(e.target.value)}
                checked={props.value === 'annually'}
              />
              <label
                ref={annualRef}
                className="pricing__interval-radio-label"
                htmlFor="pricing__annual-subscription-interval"
              >
                {t('v2.pricing.subscriptions.annual')}
              </label>

              <span className="pricing__interval-save">
                {t('v2.pricing.subscriptions.saveUpTo20Percent')}
              </span>
            </section>

            <section className="pricing__interval-radio">
              <input
                id="pricing__monthly-subscription-interval"
                type="radio"
                name="interval"
                value="monthly"
                onChange={e => props.onChange(e.target.value)}
                checked={props.value === 'monthly'}
              />
              <label
                ref={monthlyRef}
                className="pricing__interval-radio-label"
                htmlFor="pricing__monthly-subscription-interval"
              >
                {t('v2.pricing.subscriptions.monthly')}
              </label>
            </section>
            <div ref={underlineRef} className="pricing__interval-underline" />
          </div>
        </form>
      </div>
    </div>
  );
};

IntervalRadio.propTypes = {
  value: PropTypes.oneOf(['annually', 'monthly']),
  onChange: PropTypes.func,
  cardsPixelsFromTop: PropTypes.number
};

export default IntervalRadio;
