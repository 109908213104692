import new3DNALogo from '../../common/img/3DNALogo.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { joinWaitList } from '../../common/reCaptcha.helper';

const validateEmail = email => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const Waitlist = () => {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState('');
  const [isConfirmed, setConfirmed] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onInputChange = event => {
    setEmail(event.target.value);

    if (invalidEmail) {
      setInvalidEmail(false);
    }
  };

  const onSubmit = async () => {
    const isValid = validateEmail(email) !== null;

    if (!isValid) {
      return setInvalidEmail(true);
    }

    setLoading(true);

    const response = await joinWaitList(email, i18n.language);

    if (!response) {
      setError(true);
    } else {
      setConfirmed(response);
    }

    setLoading(false);
  };

  return (
    <div className="waitlist-container">
      <span className="waitlist-title">{t('waitlist.title')}</span>
      <img src={new3DNALogo} alt="3DNA" className="waitlist-logo" />
      <input
        className={`waitlist-input ${
          invalidEmail ? 'waitlist-input-error' : ''
        }`}
        placeholder={t('waitlist.input')}
        value={email}
        onChange={onInputChange}
      />
      <button
        className={`waitlist-action-btn ${loading ? 'disabled' : ''}`}
        onClick={onSubmit}
        disabled={loading}
      >
        {t('waitlist.action')}
      </button>

      {isConfirmed && (
        <span className="waitlist-confirmation-text">
          {t('waitlist.confirmation')}
        </span>
      )}

      {error && (
        <span className="waitlist-error-text">
          An error occured, please try again later
        </span>
      )}
    </div>
  );
};
