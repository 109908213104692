import React, { Component } from 'react';

import ScrollToTopOnMount from '../scrollToTopOnMount';

import Hero from './hero';
import AI from './ai';
import Header from '../header';

import EasyAccess from './easyAccess';
import Footer from '../footer';

import './theme.scss';
import Redefine from './redefine';
import AppStayConnected from './appStayConnected';
import Experience from './metalab/Experience';
import Guiding from './metalab/Guiding';

class HomeDesktop extends Component {
  constructor(props) {
    super(props);
    this.options = {
      licenseKey: process.env.REACT_APP_FULLPAGE_LICENSE,
      anchors: [
        'intuitive',
        'anywhere',
        'natural',
        'experience',
        'scale',
        'connected',
        'redefine',
        'menu'
      ],
      onLeave: this.onSectionLeave.bind(this),
      navigation: false,
      autoScrolling: false,
      fitToSection: false
    };

    this.state = {
      headerSize: null
    };
  }

  componentDidMount() {
    window.fp = new window.fullpage('#fullpage', this.options);
  }

  componentWillUnmount() {
    window.fp.destroy();
    var fpNav = document.getElementById('fp-nav');
    if (fpNav) fpNav.remove();
  }

  onSectionLeave = (origin, destination, direction) => {
    if (origin.anchor === 'intuitive' && direction === 'down') {
      this.setState({ headerSize: 'small' });
    }
    if (origin.anchor === 'anywhere' && direction === 'up') {
      if (this.heroVideo) this.heroVideo.play();
      this.setState({ headerSize: null });
    }
    if (origin.anchor === 'anywhere' && direction === 'down') {
      if (this.aiVideo) this.aiVideo.play();
      if (this.aiLibVideo) this.aiLibVideo.play();
    }
    if (origin.anchor === 'experience' && direction === 'up') {
      if (this.aiVideo) this.aiVideo.play();
      if (this.aiLibVideo) this.aiLibVideo.play();
    }
    if (origin.anchor === 'natural') {
      if (this.aiVideo) {
        this.aiVideo.pause();
        this.aiVideo.currentTime = 0;
      }

      if (this.aiLibVideo) {
        this.aiLibVideo.pause();
        this.aiLibVideo.currentTime = 0;
      }
    }
    if (
      (origin.anchor === 'intuitive' && direction === 'down') ||
      (origin.anchor === 'natural' && direction === 'up')
    ) {
      if (this.smileVideo) this.smileVideo.play();
      if (this.reviewVideo) this.reviewVideo.play();
    }
  };

  onAiLoad = (video, libVideo) => {
    this.aiVideo = video;
    this.aiLibVideo = libVideo;
  };

  onHeroLoad = video => {
    this.heroVideo = video;
  };

  onSmileLoad = video => {
    this.smileVideo = video;
  };

  onReviewLoad = video => {
    this.reviewVideo = video;
  };

  render() {
    const { headerSize } = this.state;
    return (
      <section id="home">
        <ScrollToTopOnMount />
        <Header theme="light" fixed={true} size={headerSize} />

        <div id="fullpage">
          <div className="section" id="section1">
            <Hero onLoad={this.onHeroLoad} />
          </div>
          <div className="section fp-auto-height margin-top" id="section2">
            <EasyAccess
              onSmileLoad={this.onSmileLoad}
              onReviewLoad={this.onReviewLoad}
            />
          </div>
          <div className="section fp-auto-height" id="section3">
            <AI onLoad={this.onAiLoad} />
          </div>
          <div className="section fp-auto-height" id="section4">
            <Experience />
          </div>

          <div className="section fp-auto-height" id="section5">
            <Guiding />
          </div>

          <div className="section fp-auto-height" id="section6">
            <AppStayConnected />
          </div>
          <div className="section fp-auto-height" id="section7">
            <Redefine />
          </div>
          <div className="section fp-auto-height" id="section8">
            <Footer theme="transparent" />
          </div>
        </div>
      </section>
    );
  }
}

export default HomeDesktop;
