import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../header';
import ScrollToTopOnMount from '../scrollToTopOnMount';
import './Download.scss';

import { APP_URL, TEXTS, API_URL } from '../common/constants';

import chrome from '../common/download/chrome.svg';
import edge from '../common/download/edge.svg';
import opera from '../common/download/opera.svg';

import mac from '../common/download/mac@2x.png';
import mac1x from '../common/download/mac.png';
import iOS from '../common/download/iOS@2x.png';
import iOS1x from '../common/download/iOS.png';
import android from '../common/download/android@2x.png';
import android1x from '../common/download/android.png';
import windows from '../common/download/windows@2x.png';
import windows1x from '../common/download/windows.png';
import Footer from '../footer';
import MobileFooter from '../home/mobileFooter';
import Redefine from '../home/redefine';
import { useWindowSize } from '../common/useWindowSize';

const WebBrowsers = ({ domain }) => {
  return (
    <div className="box">
      <div className="box-title">Web</div>
      <a className="box-action" href={APP_URL}>
        {domain}
      </a>
      <div className="browsers-list">
        <a
          href="https://www.google.com/chrome/browser/desktop/index.html"
          rel="noopener noreferrer"
          target="_blank"
          className="browser-link"
        >
          <div className="browser">
            <img src={chrome} alt="Chrome" className="browser-image" />
            <div className="browser-name">Chrome</div>
          </div>
        </a>
        <a
          href="https://www.microsoft.com/en-us/edge"
          target="_blank"
          rel="noopener noreferrer"
          className="browser-link"
        >
          <div className="browser">
            <img src={edge} alt="Edge" className="browser-image" />
            <div className="browser-name">Edge</div>
          </div>
        </a>
        <a
          href="https://www.opera.com/"
          rel="noopener noreferrer"
          target="_blank"
          className="browser-link"
        >
          <div className="browser">
            <img src={opera} alt="Opera" className="browser-image" />
            <div className="browser-name">Opera</div>
          </div>
        </a>
      </div>
    </div>
  );
};

const OSType = {
  MacOS: 'mac',
  Windows: 'windows'
};

const Download = () => {
  const { t } = useTranslation();
  const [appDownloadUrls, setAppDownloadUrls] = useState({
    [OSType.MacOS]: null,
    [OSType.Windows]: null
  });
  const domain = new URL(APP_URL).hostname;
  const { isMobileOrTablet } = useWindowSize();
  const apps = useMemo(() => getDownloadConfig(isMobileOrTablet), [
    isMobileOrTablet
  ]);

  useEffect(() => {
    const request = new Request(`${API_URL}/api/public/version`);
    fetch(request)
      .then(response => {
        return response.json();
      })
      .then(latestInfo => {
        setAppDownloadUrls({
          [OSType.MacOS]: latestInfo[OSType.MacOS].latest,
          [OSType.Windows]: latestInfo[OSType.Windows].latest
        });
      });
  }, []);

  return (
    <section id="download">
      <ScrollToTopOnMount />
      <Header theme="light" fixed={true} isMobile={isMobileOrTablet} />
      <div className="download-overlay" />

      <div className="download-container">
        <div className="box no-border no-hover centered">
          <div className="download-title">{t(TEXTS.download.title)}</div>
          <div className="download-description">
            {t(TEXTS.download.description)}
          </div>
        </div>
        {!isMobileOrTablet && <WebBrowsers domain={domain} />}
        {apps.map((box, idx) => {
          return (
            <React.Fragment key={`box-${idx}`}>
              {isMobileOrTablet && idx === 2 && <WebBrowsers domain={domain} />}

              <div className="box">
                <div className="box-title">{box.title}</div>
                <a
                  className="box-action"
                  href={
                    box.button.link
                      ? box.button.link
                      : appDownloadUrls[box.button.type]
                  }
                  target={box.button.target}
                  rel="noopener noreferrer"
                >
                  {t(box.button.caption)}
                </a>
                {box.images && (
                  <img
                    srcSet={`${box.images[1]} 648w, ${box.images[0]} 1296w`}
                    src={box.images[0]}
                    className="box-image"
                    alt="Store Cases"
                  />
                )}

                {box.images && isMobileOrTablet && (
                  <div className="box-image-placeholder" />
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>

      {isMobileOrTablet ? (
        <MobileFooter />
      ) : (
        <>
          <Redefine key="redefine-key" />
          <Footer theme="transparent" key="footer-key" />
        </>
      )}
    </section>
  );
};

const getDownloadConfig = isMobileOrTablet => {
  const downloadConfig = [
    {
      title: 'Mac',
      button: {
        caption: 'common.download',
        link: null,
        type: OSType.MacOS
      },
      images: [mac, mac1x]
    },
    {
      title: 'Windows',
      button: {
        caption: 'common.download',
        link: null,
        type: OSType.Windows
      },
      images: [windows, windows1x]
    },
    {
      title: 'iOS',
      button: {
        caption: 'common.download',
        link: 'https://apps.apple.com/app/id1463986434',
        target: '_blank'
      },
      images: [iOS, iOS1x]
    },
    {
      title: 'Android',
      button: {
        caption: 'common.download',
        link:
          'https://play.google.com/store/apps/details?id=com.smilecloud.android.app&utm_source=www',
        target: '_blank'
      },
      images: [android, android1x]
    }
  ];
  if (isMobileOrTablet) {
    return [
      downloadConfig[2],
      downloadConfig[3],
      downloadConfig[0],
      downloadConfig[1]
    ];
  }
  return downloadConfig;
};

export default Download;
