import { useEffect, useMemo, useState } from 'react';
import { mobileWidth, tabletWidth } from '../common/styles/_colors.scss';
const mobileWidthNum = Number.parseInt(mobileWidth, 10);
const tabletWidthNum = Number.parseInt(tabletWidth, 10);

export const useWindowSize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return useMemo(() => {
    const isTablet = width <= tabletWidthNum && width > mobileWidthNum;
    const isMobile = width <= mobileWidthNum;
    const isMobileOrTablet = isTablet || isMobile;
    return { isTablet, isMobile, isMobileOrTablet };
  }, [width]);
};
